/* theme .scss */
a {
  color: #fff;
}

a:hover {
  color: #fff;
}

.btn-primary {
  font-weight: normal;
  background: #b0d800 !important;
  border-color: #b0d800 !important;
  color: #000000 !important;
  text-transform: uppercase;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: #97b415 !important;
  border-color: #97b415 !important;
  color: #000000 !important;
}

.btn-secondary {
  background: #860fa7;
  border-color: #860fa7;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background: #a125c4;
  border-color: #a125c4;
}

.bg-dark {
  background: #000000 !important;
}

.bg-secondary {
  background: #353136 !important;
}

.bg-primary {
  background: #860fa7 !important;
}

.bg-success-0 {
  --bs-bg-opacity: 0;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

