/*Style.scss*/

/* OPEN SANS */
@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular.ttf")  format('truetype');
            
   }
@font-face {
    font-family: "Open Sans Light";
    src: url("../fonts/OpenSans-Light.ttf")  format('truetype');
           
   }
   
@font-face {
    font-family: "Open Sans Light";
    src: url("../fonts/OpenSans-Light.ttf")  format('truetype');
   }

/* Manrope */
@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-VariableFont_wght.ttf")  format('truetype');
            
   }
   @font-face {
    font-family: "Manrope Light";
    src: url("../fonts/Manrope-Light.ttf")  format('truetype');
            
   }
 
 

   body { 
     font-family: "Open Sans Light"; margin:0; font-size:1.1rem; line-height:1.7em;   
 }
  

 a, a:hover{text-decoration: none;}
 
header section{  padding: 2em 4em; 
    
    text-align: center; 
}

  
header #logo a {   
    margin:auto;
    max-width: 175px;
    display:block;
    height:auto; 
    overflow: hidden;
    border-radius: 50%;
}

header  #logo  a img{ vertical-align: bottom; width: 100%; height: auto;  }

/*
#compentences{text-align: center;}

#compentences article li{
    text-align:left;
}
*/

.private{display: none;}

#outils ul { padding:0;}
#outils ul li{ list-style: none;}


hr, hr:not([size]){ 
    height: 2px;
    width: 100%; 
    background:rgb(53, 53, 53);
    display:block;   opacity:1;
    border:none;
    position:relative; 
}
 
main {    overflow: hidden;
  position: relative;
        margin:auto; 
      padding:0;
}
main:before {

  background: url(../media/formes.svg) no-repeat;
  width: 100%;
  height: 48%;
  content: "";
  top: 0;
  z-index: 0;
  right: 0px;
  left: 0;
  margin: auto;
  position: absolute;
  transform: rotate( 0deg );
  background-size: 90% auto;

    animation-duration: 7s;
    animation-name: bg-fx;
    animation-iteration-count: infinite;
    animation-direction:alternate-reverse;
  } 
  
  @keyframes bg-fx {
    from {
        transform:  rotate(0deg); 
      width: 80%;
    }
  
    to {
        transform:  rotate(-4deg); 
      width: 82%;
    }
  }


  main:after {
    background: url("../media/formes.svg") no-repeat;
    width: 100%;
    height: 33%;
    content: "";
    bottom: 0;
    z-index: 0;
    right: -50%;
    position: absolute;
    transform: rotate( 
      173deg
       );
    background-size: 100% auto;
    animation-duration: 7s;
    animation-name: bg-fx2;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }


  @keyframes bg-fx2 {
      from {
        
        width: 98%;
      }
    
      to {
        
        width: 100%;
      }
    }

   
       
    
section{ padding:0em 5em 5em 5em; margin: auto; max-width: 1280px; position: relative;}

.row{
    --bs-gutter-x: 6em;
    --bs-gutter-y: 2em;
    margin-right: calc(var(--bs-gutter-x)/ -6);
    margin-left: calc(var(--bs-gutter-x)/ -6);

}



aside{
    padding: 2em;
}

/* TITRES */
h1{letter-spacing: 0.7em; text-transform: uppercase;}
h2{ font-family: "Manrope Light"; 
    font-size: 1.7em;
    font-weight: 300; margin-bottom : 2em; 
    text-transform: uppercase;    letter-spacing: 0.5em;
}
h3{font-size:1.5rem}
 

  
ul {padding-left: 0;}
li{list-style: none;}

#profil{text-align: center;  margin:5em auto; padding: 5em 2em }
#profil p{    font-size: 1.1em;
  letter-spacing: 0.2em;}

#sites {text-align:center; margin:auto; }
#sites ul{padding-left:0;}

#formations { position:relative;}
#formations ul{position: relative} 
#formations li{position:relative; margin: 1em 0;}
 

#centres-interets h2 {text-align:left; }

#experiences h2{text-align:center;     display: block;
  width: 100%;}
#experiences article{position:relative;  padding-bottom:2em;}
#experiences article hr{position:absolute;bottom:0; width:calc(100% - 6em);}
#experiences small {margin-bottom: 1.3em; display: block;}


#langages {text-align: center;}
 
.carousel{
  max-width: 50em; margin:auto;

  .carousel-indicators [data-bs-target]{ background:rgb(78, 209, 195); height: 7px; border-radius: 7px; padding:0; border:none;}
  .carousel-inner{
  text-align:center;
  } 
  .carousel-item{ height:22em; align-items: center;}
  .carousel-indicators{}
}


.card{background:none; max-width: 25rem; width:100%; margin: auto;}
progress{display:block;width:100%; background:transparent }

a{ text-decoration: underline;}  

#cv{text-align: center;}

.outils{
  .badge{
    padding: 0.4rem 0.8rem;
    margin-right: 0.6rem;
    margin-bottom:1rem;
    font-size: 1rem;
    font-weight: 300;
  }
  p{margin-bottom:0}
}
 
@keyframes op {
  from {
     opacity:1
   
  }

  to {
    opacity:0.7
    
  }
}
 

 @media screen and (max-width: 720px) {




    header #logo {order:1; }
    header #adresse{order:2}
    header #tel{order:3;  } 
    

    h2{letter-spacing:0.2em} 

}

@media screen and (max-width: 992px) {

  

      #experiences h2 {text-align:left; padding: 0 2em}

}


/** Animations */

main {
   position: relative;
  animation-duration: 1.5s;
  animation-name: anim;
  animation-iteration-count: 1;
   
}

#adresse,#tel{ position: relative; opacity:0;
  animation-duration: 1.5s;
  animation-name: anim2;
  animation-delay: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;}

  #cv {position: relative; opacity:0;
  animation-duration: 2s;
  animation-name: anim3;
  animation-delay:1s;
  animation-iteration-count: 1;
animation-fill-mode: forwards;}




@keyframes anim {
    from {
      top:-30px;
      opacity: 0.0;
    }
  
    to {
      top:0;
      opacity: 1;
    }
  }
  
@keyframes anim2 {
  from {
    top:-30px;
    opacity: 0.0;
  }

  to {
    top:0;
    opacity: 1;
  }
}


@keyframes anim3 {
  from {
    top:-30px;
    opacity: 0.0;
  }

  to {
    top:0;
    opacity: 1;
  }
}


   
